<template>
    <div class="lineChart">
    </div>
</template>

<script>
import {getWeekToSwipeYourCard} from '../../../api/index.js'
export default {
    name: '',
    props: {
        syncId: String,
        data: []
    },
    data() {
        return {

        }
    },
    methods: {
        setChart() {
            let color = ['#3EF3FF'];
            let xAxisData = this.data.map((v) => v.date);
            let yAxisData1 = this.data.map((v) => v.num);
            // const hexToRgba = (hex, opacity) => {
            //     let rgbaColor = '';
            //     let reg = /^#[\da-f]{6}$/i;
            //     if (reg.test(hex)) {
            //         rgbaColor = `rgba(${parseInt('0x' + hex.slice(1, 3))},${parseInt('0x' + hex.slice(3, 5))},${parseInt(
            //             '0x' + hex.slice(5, 7)
            //         )},${opacity})`;
            //     }
            //     return rgbaColor;
            // };

            let option = {
                legend: {
                    show: false
                },
                tooltip: {
                    trigger: 'axis',
                    // formatter: function (params) {
                    //     let html = '';
                    //     params.forEach((v) => {
                    //         console.log(v);
                    //         html += `<div style="color: #666;font-size: 12px;line-height: 24px">
                    //         <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                    //             color[v.componentIndex]
                    //         };"></span>
                    //         ${v.seriesName} ${v.name}月
                    //         <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 12px">${v.value}</span>
                    //         `;
                    //     });

                    //     return html;
                    // },
                    extraCssText: 'background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;',
                    // axisPointer: {
                    //     type: 'shadow',
                    // shadowStyle: {
                    //     color: '#ffffff',
                    //     shadowColor: 'rgba(225,225,225,1)',
                    //     shadowBlur: 5
                    // }
                    // }
                },
                grid: {
                    top: 20,
                    left: 0,
                    bottom: 0,
                    right: 20,
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: ["2%", "2%"],
                        axisLabel: {
                            // formatter: '{value}月',

                            textStyle: {
                                fontSize: 11,
                                fontFamily: 'Microsoft YaHei',
                                fontWeight: 400,
                                color: '#fff',
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(177,222,255,1)',
                                // type: "solid"
                            },
                            show: true
                        },
                        axisTick: {
                            show: false,
                        },
                        data: xAxisData,
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '',
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: '#fafafa',
                                fontSize: 12,
                                fontFamily: 'Microsoft YaHei',
                                fontWeight: 400,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(177,222,255,0.4)',
                                type:'dashed'
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '近一周借阅情况',
                        type: 'line',
                        smooth: true,
                        // showSymbol: false,
                        symbolSize: 8,
                        symbol: 'circle', //标记的图形为实心圆
                        zlevel: 3,
                        itemStyle: {
                            normal: {
                                color: '#FFCD3E',
                                borderColor: '#FFCD3E', //圆点透明 边框
                                borderWidth: 1,
                            },
                        },
                        lineStyle: {
                            normal: {
                                width:3,
                                color: color[0],
                                // shadowColor: hexToRgba(color[0], 0.2),
                                // shadowBlur: 100,
                                // shadowOffsetY: 20 // 此处设置凸出的偏移量
                            },
                            // shadowBlur: 10,
                            // shadowColor: hexToRgba(color[0], 0.2),
                            // shadowOffsetY: 20,
                        },

                        areaStyle: {
                            opacity: 1,
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 0.3,
                                colorStops: [{
                                    offset: 0, color: "rgba(62, 243, 255, 0.8)" // 0% 处的颜色
                                },
                                    {
                                        offset: 0.4, color: "rgba(62, 243, 255, 0.4)" // 40% 处的颜色
                                    }, {
                                        offset: 1, color: "rgba(62, 243, 255, 0.1)"// 100% 处的颜色
                                    }],
                                global: false // 缺省为 false
                            }
                        },
                        data: yAxisData1,
                    },
                ],
            };
            let myChart = this.$echarts(this.$el);

            myChart.clear();
            myChart.resize()

            myChart.setOption(option);
            // let obj = {
            //     type: 'highlight',
            //     name: []
            // };
            // for (let i = 0; i < this.data.data.length - 1; i++) {
            //     obj.name.push(this.data.data[i].name)
            // }
            // myChart.dispatchAction(obj);
            // myChart.on('mouseout', function () {
            //     myChart.dispatchAction(obj);
            // });
        },
    },
    mounted() {
        if (!this.syncId) return
        getWeekToSwipeYourCard({syncId: this.syncId}).then(res => {
            this.data = res.data
            this.setChart();
        })
    },
}
</script>

<style lang="less" scoped>
.lineChart {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>